// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.



//= require "bootstrap/js/bootstrap.bundl"
//= require "aos/dist/aoss"
//= require "swiper/swiper-bundle.min"
//= require "purecounter/purecounter"
//= require "isotope-layout/isotope.pkgd.min"
//= require "glightbox/dist/js/glightbox.min"

import 'bootstrap-icons/font/bootstrap-icons.css'
//import 'aos/dist/aos.css'
//import 'swiper/swiper-bundle.min'
//import 'purecounter/purecounter'
//import 'isotope-layout/isotope.pkgd.min'
import 'glightbox/dist/js/glightbox.min'

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

